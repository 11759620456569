var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.reservationsData.createdBy ? _c('div', {
    staticClass: "d-flex align-items-stretch gap-2 mb-50",
    class: _vm.isMobileView ? 'd-flex-col-center' : 'd-flex-center'
  }, [_c('AppCollapse', {
    staticClass: "flex-1",
    attrs: {
      "type": "border-info card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "is-visible": false,
      "title": "",
      "class-header": "card-header bg-gray-dark py-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-white m-0 text-uppercase fw-700",
          class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
        }, [_vm._v(" " + _vm._s(_vm.isRTL ? _vm.$t('reservation.bookerDetails.rtlTitle') : _vm.$t('reservation.bookerDetails.title')) + " ")])];
      },
      proxy: true
    }], null, false, 4134335663)
  }, [_vm.reservationsData.createdBy && (_vm.reservationsData.createdBy.lastName || _vm.reservationsData.createdBy.firstName) ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookerDetails.name')) + ": ")]), _vm._v(" " + _vm._s("".concat(_vm.reservationsData.createdBy.lastName || '', " ").concat(_vm.reservationsData.createdBy.firstName || '')) + " "), _c('small', {
    staticClass: "font-italic"
  }, [_vm._v("("), _c('span', {
    staticClass: "text-info font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.reservationsData.createdBy.username.toUpperCase()))]), _vm._v(")")])]) : _vm._e(), _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.phoneNumber ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookerDetails.phone')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.createdBy.phoneNumber) + " ")]) : _vm._e(), _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.emailAddress ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookerDetails.email')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.createdBy.emailAddress) + " ")]) : _vm._e(), _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookerDetails.agency')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.createdBy.agency ? "".concat(_vm.reservationsData.createdBy.agency.agencyName, " (").concat(_vm.reservationsData.createdBy.agency.agencyCode.toUpperCase(), ")") : _vm.reservationsData.agency) + " ")]) : _vm._e(), _vm.isRoleF1 && _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency && _vm.reservationsData.createdBy.agency.parentAgency && _vm.reservationsData.createdBy.agency.parentAgency.id !== 1000000 ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookerDetails.parentAgency')) + ": ")]), _vm._v(" " + _vm._s("".concat(_vm.reservationsData.createdBy.agency.parentAgency.agencyName, " (").concat(_vm.reservationsData.createdBy.agency.parentAgency.agencyCode.toUpperCase(), ")")) + " ")]) : _vm._e()])], 1), !_vm.isVisibleCustomerInfo && _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency && (_vm.reservationsData.createdBy.agency.id === _vm.agencyLoggedIn.id || _vm.reservationsData.createdBy.agency.parentAgency.id === _vm.agencyLoggedIn.id) ? _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "py-75",
    attrs: {
      "variant": "outline-dark",
      "title": "Hiện thông tin khách hàng"
    },
    on: {
      "click": function click($event) {
        _vm.isVisibleCustomerInfo = !_vm.isVisibleCustomerInfo;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer text-dark",
    attrs: {
      "icon": "EyeOffIcon",
      "size": "18"
    }
  })], 1) : _vm._e(), _vm.isVisibleCustomerInfo && _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency && (_vm.reservationsData.createdBy.agency.id === _vm.agencyLoggedIn.id || _vm.reservationsData.createdBy.agency.parentAgency.id === _vm.agencyLoggedIn.id) ? _c('AppCollapse', {
    staticClass: "flex-1",
    attrs: {
      "type": "border-info card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "d-flex flex-column",
    attrs: {
      "is-visible": false,
      "title": "",
      "class-header": "card-header bg-gray-dark py-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-white m-0 text-uppercase fw-700",
          class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.title')) + " ")]), _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "rounded-circle p-25 mr-50",
          attrs: {
            "variant": "flat-dark",
            "title": "Ẩn thông tin khách hàng"
          },
          on: {
            "click": function click($event) {
              _vm.isVisibleCustomerInfo = !_vm.isVisibleCustomerInfo;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer text-dark",
          attrs: {
            "icon": "EyeIcon",
            "size": "18"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 740790403)
  }, [_c('div', {
    staticClass: "py-1"
  }, [_vm.reservationRetailPaxContact ? _c('div', [_vm.reservationsData.domain ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.domain')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.domain) + " ")]) : _vm._e(), _vm.reservationRetailPaxContact ? _c('div', [_c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.email')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationRetailPaxContact[0]) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.name')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationRetailPaxContact[1]) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.phone')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationRetailPaxContact[2]) + " ")]), _vm.reservationRetailPaxContact[3] ? _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.address')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationRetailPaxContact[3]) + " ")]) : _vm._e()]) : _vm._e()]) : _c('div', [_c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.name')) + ": ")]), _vm._v(" " + _vm._s("".concat(_vm.reservationsData.paxContact.lastName, " ").concat(_vm.reservationsData.paxContact.firstName)) + " "), _c('small', {
    staticClass: "font-italic"
  }, [_vm._v("(" + _vm._s(_vm.reservationsData.paxContact.code) + ")")])]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.phone')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.paxContact.phoneNumber) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.customerDetails.email')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.paxContact.emailAddress) + " ")])])])])], 1) : _vm._e(), !_vm.isVisibleBookingInvoiceInfo && _vm.reservationsData.bookingInvoice && _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency && (_vm.reservationsData.createdBy.agency.id === _vm.agencyLoggedIn.id || _vm.reservationsData.createdBy.agency.parentAgency.id === _vm.agencyLoggedIn.id) ? _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "py-75 mb-1",
    attrs: {
      "variant": "flat-warning",
      "title": "Hiện thông tin hóa đơn"
    },
    on: {
      "click": function click($event) {
        _vm.isVisibleBookingInvoiceInfo = !_vm.isVisibleBookingInvoiceInfo;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer text-warning",
    attrs: {
      "icon": "EyeOffIcon"
    }
  })], 1) : _vm._e(), _vm.isVisibleBookingInvoiceInfo && _vm.reservationsData.bookingInvoice && _vm.reservationsData.createdBy && _vm.reservationsData.createdBy.agency && (_vm.reservationsData.createdBy.agency.id === _vm.agencyLoggedIn.id || _vm.reservationsData.createdBy.agency.parentAgency.id === _vm.agencyLoggedIn.id) ? _c('b-card', {
    staticClass: "border-warning w-100",
    attrs: {
      "header-bg-variant": "warning py-25 mb-1",
      "header-class": _vm.isMobileView ? 'px-1 flex-nowrap' : 'justify-content-between'
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-white m-0",
          class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.title')) + " ")]), _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          class: _vm.isMobileView ? 'p-75' : 'py-75',
          attrs: {
            "variant": "flat-dark",
            "title": "Ẩn thông tin hóa đơn"
          },
          on: {
            "click": function click($event) {
              _vm.isVisibleBookingInvoiceInfo = !_vm.isVisibleBookingInvoiceInfo;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer text-white",
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 652155007)
  }, [_c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.companyName')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.companyName) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.address')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.address) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.address')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.cityName) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.taxCode')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.taxCode) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.receiver')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.receiver) + " ")]), _c('div', {
    staticClass: "mb-25"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingInvoice.email')) + ": ")]), _vm._v(" " + _vm._s(_vm.reservationsData.bookingInvoice.email) + " ")])]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }