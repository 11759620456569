<template>
  <div
    v-if="reservationsData.createdBy"
    class="d-flex align-items-stretch gap-2 mb-50"
    :class="isMobileView ? 'd-flex-col-center' : 'd-flex-center'"
  >
    <!-- ANCHOR AGENCY -->
    <AppCollapse
      type="border-info card"
      class="flex-1"
    >
      <AppCollapseItem
        :is-visible="false"
        title
        class-header="card-header bg-gray-dark py-75"
        class=""
      >
        <template #header>
          <span
            class="text-white m-0 text-uppercase fw-700"
            :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
          >
            {{ isRTL ? $t('reservation.bookerDetails.rtlTitle') : $t('reservation.bookerDetails.title') }}
          </span>
        </template>

        <div
          v-if="reservationsData.createdBy && (reservationsData.createdBy.lastName || reservationsData.createdBy.firstName)"
          class="mb-25"
        >
          <span class="font-weight-bolder">
            {{ $t('reservation.bookerDetails.name') }}:
          </span>
          {{ `${reservationsData.createdBy.lastName || ''} ${reservationsData.createdBy.firstName || ''}` }}
          <small class="font-italic">(<span class="text-info font-weight-bolder">{{ reservationsData.createdBy.username.toUpperCase() }}</span>)</small>
        </div>

        <div
          v-if="reservationsData.createdBy && reservationsData.createdBy.phoneNumber"
          class="mb-25"
        >
          <span class="font-weight-bolder">
            {{ $t('reservation.bookerDetails.phone') }}:
          </span>
          {{ reservationsData.createdBy.phoneNumber }}
        </div>

        <div
          v-if="reservationsData.createdBy && reservationsData.createdBy.emailAddress"
          class="mb-25"
        >
          <span class="font-weight-bolder">
            {{ $t('reservation.bookerDetails.email') }}:
          </span>
          {{ reservationsData.createdBy.emailAddress }}
        </div>

        <div
          v-if="reservationsData.createdBy && reservationsData.createdBy.agency"
          class="mb-25"
        >
          <span class="font-weight-bolder">
            {{ $t('reservation.bookerDetails.agency') }}:
          </span>
          {{ reservationsData.createdBy.agency ? `${reservationsData.createdBy.agency.agencyName} (${reservationsData.createdBy.agency.agencyCode.toUpperCase()})` : reservationsData.agency }}
        </div>

        <div
          v-if="isRoleF1 && reservationsData.createdBy && reservationsData.createdBy.agency && reservationsData.createdBy.agency.parentAgency && reservationsData.createdBy.agency.parentAgency.id !== 1000000"
          class="mb-25"
        >
          <span class="font-weight-bolder">
            {{ $t('reservation.bookerDetails.parentAgency') }}:
          </span>
          {{ `${reservationsData.createdBy.agency.parentAgency.agencyName} (${reservationsData.createdBy.agency.parentAgency.agencyCode.toUpperCase()})` }}
        </div>
      </AppCollapseItem>
    </AppCollapse>
    <!-- ANCHOR CUSTOMER -->
    <BButton
      v-if="!isVisibleCustomerInfo && reservationsData.createdBy && reservationsData.createdBy.agency && (reservationsData.createdBy.agency.id === agencyLoggedIn.id || (reservationsData.createdBy.agency.parentAgency.id === agencyLoggedIn.id))"
      v-b-tooltip.hover.window
      variant="outline-dark"
      class="py-75"
      title="Hiện thông tin khách hàng"
      @click="isVisibleCustomerInfo = !isVisibleCustomerInfo"
    >
      <feather-icon
        class="cursor-pointer text-dark"
        icon="EyeOffIcon"
        size="18"
      />
    </BButton>

    <AppCollapse
      v-if="isVisibleCustomerInfo && reservationsData.createdBy && reservationsData.createdBy.agency && (reservationsData.createdBy.agency.id === agencyLoggedIn.id || (reservationsData.createdBy.agency.parentAgency.id === agencyLoggedIn.id))"
      type="border-info card"
      class="flex-1"
    >
      <AppCollapseItem
        :is-visible="false"
        title
        class-header="card-header bg-gray-dark py-75"
        class="d-flex flex-column"
      >
        <template #header>
          <span
            class="text-white m-0 text-uppercase fw-700"
            :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
          >
            {{ $t('reservation.customerDetails.title') }}
          </span>
          <BButton
            v-b-tooltip.hover.window
            variant="flat-dark"
            title="Ẩn thông tin khách hàng"
            class="rounded-circle p-25 mr-50"
            @click="isVisibleCustomerInfo = !isVisibleCustomerInfo"
          >
            <feather-icon
              class="cursor-pointer text-dark"
              icon="EyeIcon"
              size="18"
            />
          </BButton>
        </template>

        <div class="py-1">
          <div v-if="reservationRetailPaxContact">
            <div
              v-if="reservationsData.domain"
              class="mb-25"
            >
              <span class="font-weight-bolder">
                {{ $t('reservation.customerDetails.domain') }}:
              </span>
              {{ reservationsData.domain }}
            </div>
            <div v-if="reservationRetailPaxContact">
              <div class="mb-25">
                <span class="font-weight-bolder">
                  {{ $t('reservation.customerDetails.email') }}:
                </span>
                {{ reservationRetailPaxContact[0] }}
              </div>
              <div class="mb-25">
                <span class="font-weight-bolder">
                  {{ $t('reservation.customerDetails.name') }}:
                </span>
                {{ reservationRetailPaxContact[1] }}
              </div>
              <div class="mb-25">
                <span class="font-weight-bolder">
                  {{ $t('reservation.customerDetails.phone') }}:
                </span>
                {{ reservationRetailPaxContact[2] }}
              </div>
              <div
                v-if="reservationRetailPaxContact[3]"
                class="mb-25"
              >
                <span class="font-weight-bolder">
                  {{ $t('reservation.customerDetails.address') }}:
                </span>
                {{ reservationRetailPaxContact[3] }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mb-25">
              <span class="font-weight-bolder">
                {{ $t('reservation.customerDetails.name') }}:
              </span>
              {{ `${reservationsData.paxContact.lastName} ${reservationsData.paxContact.firstName}` }}
              <small class="font-italic">({{ reservationsData.paxContact.code }})</small>
            </div>
            <div class="mb-25">
              <span class="font-weight-bolder">
                {{ $t('reservation.customerDetails.phone') }}:
              </span>
              {{ reservationsData.paxContact.phoneNumber }}
            </div>
            <div class="mb-25">
              <span class="font-weight-bolder">
                {{ $t('reservation.customerDetails.email') }}:
              </span>
              {{ reservationsData.paxContact.emailAddress }}
            </div>
          </div>
        </div>
      </AppCollapseItem>
    </AppCollapse>

    <!-- ANCHOR BOOKING INVOICE -->
    <BButton
      v-if="!isVisibleBookingInvoiceInfo && reservationsData.bookingInvoice && reservationsData.createdBy && reservationsData.createdBy.agency && (reservationsData.createdBy.agency.id === agencyLoggedIn.id || (reservationsData.createdBy.agency.parentAgency.id === agencyLoggedIn.id))"
      v-b-tooltip.hover.window
      variant="flat-warning"
      class="py-75 mb-1"
      title="Hiện thông tin hóa đơn"
      @click="isVisibleBookingInvoiceInfo = !isVisibleBookingInvoiceInfo"
    >
      <feather-icon
        class="cursor-pointer text-warning"
        icon="EyeOffIcon"
      />
    </BButton>
    <b-card
      v-if="isVisibleBookingInvoiceInfo && reservationsData.bookingInvoice && reservationsData.createdBy && reservationsData.createdBy.agency && (reservationsData.createdBy.agency.id === agencyLoggedIn.id || (reservationsData.createdBy.agency.parentAgency.id === agencyLoggedIn.id))"
      header-bg-variant="warning py-25 mb-1"
      class="border-warning w-100"
      :header-class="isMobileView ? 'px-1 flex-nowrap' : 'justify-content-between'"
    >
      <template #header>
        <span
          class="text-white m-0"
          :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
        >
          {{ $t('reservation.bookingInvoice.title') }}
        </span>
        <BButton
          v-b-tooltip.hover.window
          variant="flat-dark"
          title="Ẩn thông tin hóa đơn"
          :class="isMobileView ? 'p-75' : 'py-75'"
          @click="isVisibleBookingInvoiceInfo = !isVisibleBookingInvoiceInfo"
        >
          <feather-icon
            class="cursor-pointer text-white"
            icon="EyeIcon"
          />
        </BButton>
      </template>
      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.companyName') }}:
        </span>
        {{ reservationsData.bookingInvoice.companyName }}
      </div>

      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.address') }}:
        </span>
        {{ reservationsData.bookingInvoice.address }}
      </div>

      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.address') }}:
        </span>
        {{ reservationsData.bookingInvoice.cityName }}
      </div>

      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.taxCode') }}:
        </span>
        {{ reservationsData.bookingInvoice.taxCode }}
      </div>

      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.receiver') }}:
        </span>
        {{ reservationsData.bookingInvoice.receiver }}
      </div>

      <div
        class="mb-25"
      >
        <span class="font-weight-bolder">
          {{ $t('reservation.bookingInvoice.email') }}:
        </span>
        {{ reservationsData.bookingInvoice.email }}
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, VBTooltip } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

import store from '@/store'

export default {
  components: {
    BCard,
    BButton,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const agencyLoggedIn = computed(() => store.getters['userStore/getAgencyData'])
    const isVisibleCustomerInfo = ref(true) // ẩn hiện card thông tin khách hàng
    const isVisibleBookingInvoiceInfo = ref(true) // ẩn hiện card thông tin hoá đơn
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

    const reservationRetailPaxContact = props.reservationsData.retailPaxContact && props.reservationsData.retailPaxContact.split(' | ')
    const isRTL = props.reservationsData?.createdBy?.agency?.agencyCode?.endsWith('-RTL') ?? false

    return {
      agencyLoggedIn,
      isVisibleCustomerInfo,
      isVisibleBookingInvoiceInfo,
      isRoleF1,
      reservationRetailPaxContact,
      isRTL,
    }
  },
}
</script>
